import { default as consort_45differenceQKIhNsaqSBMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/consort-difference.vue?macro=true";
import { default as indexX8ed2Zcj3aMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/category/[category]/index.vue?macro=true";
import { default as indexmXW3pHT8NqMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/index.vue?macro=true";
import { default as indexqAy1gnUPf0Meta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/events/[event]/index.vue?macro=true";
import { default as index4ppyoi6RrpMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/events/index.vue?macro=true";
import { default as our_45processc14rjING2kMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/our-process.vue?macro=true";
import { default as our_45storyx2WubOaYvdMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/our-story.vue?macro=true";
import { default as our_45teamHa7gVBiGa5Meta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/our-team.vue?macro=true";
import { default as why_45consortCQiJzCbBhXMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/why-consort.vue?macro=true";
import { default as callbackAATxTFr5GGMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/callback.vue?macro=true";
import { default as _91_46_46_46slug_93NHFW0XeLHPMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexkOYz3k3HIaMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as career_45opportunitiess2fVXiYBIIMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as contact_45userT3CDMlAyMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/contact-us/contact-us.vue?macro=true";
import { default as for_45realtors80T5RF9UHgMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/contact-us/for-realtors.vue?macro=true";
import { default as our_45locationsEEeLT221cCMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as request_45an_45appointmentUgW0kYh7FBMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/contact-us/request-an-appointment.vue?macro=true";
import { default as join_45vip_45listY7pJfmqOJOMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/current-offers/join-vip-list.vue?macro=true";
import { default as why_45buy_45nowKQz0LsMSU6Meta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/current-offers/why-buy-now.vue?macro=true";
import { default as indexvG8WMiRZqnMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/display-homes/[model]/index.vue?macro=true";
import { default as indexhonpnwEaa0Meta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/heritage-gallery/index.vue?macro=true";
import { default as indexTpv5ioSy58Meta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/hometown-gallery/index.vue?macro=true";
import { default as indexzOS5vQvPb7Meta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/photos/index.vue?macro=true";
import { default as indexkc7HvUoZBNMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/product-gallery/index.vue?macro=true";
import { default as index5vNjNSsUUXMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/video/index.vue?macro=true";
import { default as indexTNkfJQ6P1yMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/virtual-tours/index.vue?macro=true";
import { default as indexZ4zWIe3cXsMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/index.vue?macro=true";
import { default as interactive_45home_45designidu9bHiViXMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/interactive-home-design.vue?macro=true";
import { default as my_45favoritesQpXF7BZNIEMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as indexZPUz577qU9Meta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/new-homes/homes/[home]/index.vue?macro=true";
import { default as indexPMqrLmYzxLMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/new-homes/plans/[plan]/index.vue?macro=true";
import { default as indexJq31VJV3RxMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/new-neighborhoods/[location]/[community]/index.vue?macro=true";
import { default as build_45on_45your_45lotxexrvF7e8WMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/our-homes/build-on-your-lot.vue?macro=true";
import { default as design_45processr3MjNTyIbHMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/our-homes/design-process.vue?macro=true";
import { default as financingFd0T21TSU5Meta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/our-homes/financing.vue?macro=true";
import { default as indexoQ8J0TFk2LMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacy_45policyGMpgtjQ7RaMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_93OitKyMtkIfMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/promotions/[...slug].vue?macro=true";
import { default as indexeaeBqo94jcMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/promotions/index.vue?macro=true";
import { default as sandboxSpB5KEbBVCMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as self_45guided_45home_45toursDVCME42KKfMeta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/self-guided-home-tours.vue?macro=true";
import { default as _91testimonial_93F3EAmNoh90Meta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/testimonials/[testimonial].vue?macro=true";
import { default as where_45we_45buildIJwjNUbKR3Meta } from "/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/where-we-build.vue?macro=true";
export default [
  {
    name: consort_45differenceQKIhNsaqSBMeta?.name ?? "about-consort-difference",
    path: consort_45differenceQKIhNsaqSBMeta?.path ?? "/about/consort-difference",
    meta: consort_45differenceQKIhNsaqSBMeta || {},
    alias: consort_45differenceQKIhNsaqSBMeta?.alias || [],
    redirect: consort_45differenceQKIhNsaqSBMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/consort-difference.vue").then(m => m.default || m)
  },
  {
    name: indexX8ed2Zcj3aMeta?.name ?? "about-consort-homes-reviews-and-testimonials-category-category",
    path: indexX8ed2Zcj3aMeta?.path ?? "/about/consort-homes-reviews-and-testimonials/category/:category()",
    meta: indexX8ed2Zcj3aMeta || {},
    alias: indexX8ed2Zcj3aMeta?.alias || [],
    redirect: indexX8ed2Zcj3aMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmXW3pHT8NqMeta?.name ?? "about-consort-homes-reviews-and-testimonials",
    path: indexmXW3pHT8NqMeta?.path ?? "/about/consort-homes-reviews-and-testimonials",
    meta: indexmXW3pHT8NqMeta || {},
    alias: indexmXW3pHT8NqMeta?.alias || [],
    redirect: indexmXW3pHT8NqMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: indexqAy1gnUPf0Meta?.name ?? "about-events-event",
    path: indexqAy1gnUPf0Meta?.path ?? "/about/events/:event()",
    meta: indexqAy1gnUPf0Meta || {},
    alias: indexqAy1gnUPf0Meta?.alias || [],
    redirect: indexqAy1gnUPf0Meta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: index4ppyoi6RrpMeta?.name ?? "about-events",
    path: index4ppyoi6RrpMeta?.path ?? "/about/events",
    meta: index4ppyoi6RrpMeta || {},
    alias: index4ppyoi6RrpMeta?.alias || [],
    redirect: index4ppyoi6RrpMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/events/index.vue").then(m => m.default || m)
  },
  {
    name: our_45processc14rjING2kMeta?.name ?? "about-our-process",
    path: our_45processc14rjING2kMeta?.path ?? "/about/our-process",
    meta: our_45processc14rjING2kMeta || {},
    alias: our_45processc14rjING2kMeta?.alias || [],
    redirect: our_45processc14rjING2kMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/our-process.vue").then(m => m.default || m)
  },
  {
    name: our_45storyx2WubOaYvdMeta?.name ?? "about-our-story",
    path: our_45storyx2WubOaYvdMeta?.path ?? "/about/our-story",
    meta: our_45storyx2WubOaYvdMeta || {},
    alias: our_45storyx2WubOaYvdMeta?.alias || [],
    redirect: our_45storyx2WubOaYvdMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/our-story.vue").then(m => m.default || m)
  },
  {
    name: our_45teamHa7gVBiGa5Meta?.name ?? "about-our-team",
    path: our_45teamHa7gVBiGa5Meta?.path ?? "/about/our-team",
    meta: our_45teamHa7gVBiGa5Meta || {},
    alias: our_45teamHa7gVBiGa5Meta?.alias || [],
    redirect: our_45teamHa7gVBiGa5Meta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/our-team.vue").then(m => m.default || m)
  },
  {
    name: why_45consortCQiJzCbBhXMeta?.name ?? "about-why-consort",
    path: why_45consortCQiJzCbBhXMeta?.path ?? "/about/why-consort",
    meta: why_45consortCQiJzCbBhXMeta || {},
    alias: why_45consortCQiJzCbBhXMeta?.alias || [],
    redirect: why_45consortCQiJzCbBhXMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/about/why-consort.vue").then(m => m.default || m)
  },
  {
    name: callbackAATxTFr5GGMeta?.name ?? "callback",
    path: callbackAATxTFr5GGMeta?.path ?? "/callback",
    meta: callbackAATxTFr5GGMeta || {},
    alias: callbackAATxTFr5GGMeta?.alias || [],
    redirect: callbackAATxTFr5GGMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93NHFW0XeLHPMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93NHFW0XeLHPMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93NHFW0XeLHPMeta || {},
    alias: _91_46_46_46slug_93NHFW0XeLHPMeta?.alias || [],
    redirect: _91_46_46_46slug_93NHFW0XeLHPMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexkOYz3k3HIaMeta?.name ?? "campaigns",
    path: indexkOYz3k3HIaMeta?.path ?? "/campaigns",
    meta: indexkOYz3k3HIaMeta || {},
    alias: indexkOYz3k3HIaMeta?.alias || [],
    redirect: indexkOYz3k3HIaMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiess2fVXiYBIIMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiess2fVXiYBIIMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiess2fVXiYBIIMeta || {},
    alias: career_45opportunitiess2fVXiYBIIMeta?.alias || [],
    redirect: career_45opportunitiess2fVXiYBIIMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: contact_45userT3CDMlAyMeta?.name ?? "contact-us-contact-us",
    path: contact_45userT3CDMlAyMeta?.path ?? "/contact-us/contact-us",
    meta: contact_45userT3CDMlAyMeta || {},
    alias: contact_45userT3CDMlAyMeta?.alias || [],
    redirect: contact_45userT3CDMlAyMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/contact-us/contact-us.vue").then(m => m.default || m)
  },
  {
    name: for_45realtors80T5RF9UHgMeta?.name ?? "contact-us-for-realtors",
    path: for_45realtors80T5RF9UHgMeta?.path ?? "/contact-us/for-realtors",
    meta: for_45realtors80T5RF9UHgMeta || {},
    alias: for_45realtors80T5RF9UHgMeta?.alias || [],
    redirect: for_45realtors80T5RF9UHgMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/contact-us/for-realtors.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsEEeLT221cCMeta?.name ?? "contact-us-our-locations",
    path: our_45locationsEEeLT221cCMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsEEeLT221cCMeta || {},
    alias: our_45locationsEEeLT221cCMeta?.alias || [],
    redirect: our_45locationsEEeLT221cCMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: request_45an_45appointmentUgW0kYh7FBMeta?.name ?? "contact-us-request-an-appointment",
    path: request_45an_45appointmentUgW0kYh7FBMeta?.path ?? "/contact-us/request-an-appointment",
    meta: request_45an_45appointmentUgW0kYh7FBMeta || {},
    alias: request_45an_45appointmentUgW0kYh7FBMeta?.alias || [],
    redirect: request_45an_45appointmentUgW0kYh7FBMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/contact-us/request-an-appointment.vue").then(m => m.default || m)
  },
  {
    name: join_45vip_45listY7pJfmqOJOMeta?.name ?? "current-offers-join-vip-list",
    path: join_45vip_45listY7pJfmqOJOMeta?.path ?? "/current-offers/join-vip-list",
    meta: join_45vip_45listY7pJfmqOJOMeta || {},
    alias: join_45vip_45listY7pJfmqOJOMeta?.alias || [],
    redirect: join_45vip_45listY7pJfmqOJOMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/current-offers/join-vip-list.vue").then(m => m.default || m)
  },
  {
    name: why_45buy_45nowKQz0LsMSU6Meta?.name ?? "current-offers-why-buy-now",
    path: why_45buy_45nowKQz0LsMSU6Meta?.path ?? "/current-offers/why-buy-now",
    meta: why_45buy_45nowKQz0LsMSU6Meta || {},
    alias: why_45buy_45nowKQz0LsMSU6Meta?.alias || [],
    redirect: why_45buy_45nowKQz0LsMSU6Meta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/current-offers/why-buy-now.vue").then(m => m.default || m)
  },
  {
    name: indexvG8WMiRZqnMeta?.name ?? "display-homes-model",
    path: indexvG8WMiRZqnMeta?.path ?? "/display-homes/:model()",
    meta: indexvG8WMiRZqnMeta || {},
    alias: indexvG8WMiRZqnMeta?.alias || [],
    redirect: indexvG8WMiRZqnMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/display-homes/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhonpnwEaa0Meta?.name ?? "gallery-heritage-gallery",
    path: indexhonpnwEaa0Meta?.path ?? "/gallery/heritage-gallery",
    meta: indexhonpnwEaa0Meta || {},
    alias: indexhonpnwEaa0Meta?.alias || [],
    redirect: indexhonpnwEaa0Meta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/heritage-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexTpv5ioSy58Meta?.name ?? "gallery-hometown-gallery",
    path: indexTpv5ioSy58Meta?.path ?? "/gallery/hometown-gallery",
    meta: indexTpv5ioSy58Meta || {},
    alias: indexTpv5ioSy58Meta?.alias || [],
    redirect: indexTpv5ioSy58Meta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/hometown-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexzOS5vQvPb7Meta?.name ?? "gallery-photos",
    path: indexzOS5vQvPb7Meta?.path ?? "/gallery/photos",
    meta: indexzOS5vQvPb7Meta || {},
    alias: indexzOS5vQvPb7Meta?.alias || [],
    redirect: indexzOS5vQvPb7Meta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/photos/index.vue").then(m => m.default || m)
  },
  {
    name: indexkc7HvUoZBNMeta?.name ?? "gallery-product-gallery",
    path: indexkc7HvUoZBNMeta?.path ?? "/gallery/product-gallery",
    meta: indexkc7HvUoZBNMeta || {},
    alias: indexkc7HvUoZBNMeta?.alias || [],
    redirect: indexkc7HvUoZBNMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/product-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: index5vNjNSsUUXMeta?.name ?? "gallery-video",
    path: index5vNjNSsUUXMeta?.path ?? "/gallery/video",
    meta: index5vNjNSsUUXMeta || {},
    alias: index5vNjNSsUUXMeta?.alias || [],
    redirect: index5vNjNSsUUXMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/video/index.vue").then(m => m.default || m)
  },
  {
    name: indexTNkfJQ6P1yMeta?.name ?? "gallery-virtual-tours",
    path: indexTNkfJQ6P1yMeta?.path ?? "/gallery/virtual-tours",
    meta: indexTNkfJQ6P1yMeta || {},
    alias: indexTNkfJQ6P1yMeta?.alias || [],
    redirect: indexTNkfJQ6P1yMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/gallery/virtual-tours/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ4zWIe3cXsMeta?.name ?? "index",
    path: indexZ4zWIe3cXsMeta?.path ?? "/",
    meta: indexZ4zWIe3cXsMeta || {},
    alias: indexZ4zWIe3cXsMeta?.alias || [],
    redirect: indexZ4zWIe3cXsMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: interactive_45home_45designidu9bHiViXMeta?.name ?? "interactive-home-design",
    path: interactive_45home_45designidu9bHiViXMeta?.path ?? "/interactive-home-design",
    meta: interactive_45home_45designidu9bHiViXMeta || {},
    alias: interactive_45home_45designidu9bHiViXMeta?.alias || [],
    redirect: interactive_45home_45designidu9bHiViXMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/interactive-home-design.vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesQpXF7BZNIEMeta?.name ?? "my-favorites",
    path: my_45favoritesQpXF7BZNIEMeta?.path ?? "/my-favorites",
    meta: my_45favoritesQpXF7BZNIEMeta || {},
    alias: my_45favoritesQpXF7BZNIEMeta?.alias || [],
    redirect: my_45favoritesQpXF7BZNIEMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: indexZPUz577qU9Meta?.name ?? "new-homes-homes-home",
    path: indexZPUz577qU9Meta?.path ?? "/new-homes/homes/:home()",
    meta: indexZPUz577qU9Meta || {},
    alias: indexZPUz577qU9Meta?.alias || [],
    redirect: indexZPUz577qU9Meta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/new-homes/homes/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMqrLmYzxLMeta?.name ?? "new-homes-plans-plan",
    path: indexPMqrLmYzxLMeta?.path ?? "/new-homes/plans/:plan()",
    meta: indexPMqrLmYzxLMeta || {},
    alias: indexPMqrLmYzxLMeta?.alias || [],
    redirect: indexPMqrLmYzxLMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/new-homes/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJq31VJV3RxMeta?.name ?? "new-neighborhoods-location-community",
    path: indexJq31VJV3RxMeta?.path ?? "/new-neighborhoods/:location()/:community()",
    meta: indexJq31VJV3RxMeta || {},
    alias: indexJq31VJV3RxMeta?.alias || [],
    redirect: indexJq31VJV3RxMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/new-neighborhoods/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: build_45on_45your_45lotxexrvF7e8WMeta?.name ?? "our-homes-build-on-your-lot",
    path: build_45on_45your_45lotxexrvF7e8WMeta?.path ?? "/our-homes/build-on-your-lot",
    meta: build_45on_45your_45lotxexrvF7e8WMeta || {},
    alias: build_45on_45your_45lotxexrvF7e8WMeta?.alias || [],
    redirect: build_45on_45your_45lotxexrvF7e8WMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/our-homes/build-on-your-lot.vue").then(m => m.default || m)
  },
  {
    name: design_45processr3MjNTyIbHMeta?.name ?? "our-homes-design-process",
    path: design_45processr3MjNTyIbHMeta?.path ?? "/our-homes/design-process",
    meta: design_45processr3MjNTyIbHMeta || {},
    alias: design_45processr3MjNTyIbHMeta?.alias || [],
    redirect: design_45processr3MjNTyIbHMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/our-homes/design-process.vue").then(m => m.default || m)
  },
  {
    name: financingFd0T21TSU5Meta?.name ?? "our-homes-financing",
    path: financingFd0T21TSU5Meta?.path ?? "/our-homes/financing",
    meta: financingFd0T21TSU5Meta || {},
    alias: financingFd0T21TSU5Meta?.alias || [],
    redirect: financingFd0T21TSU5Meta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/our-homes/financing.vue").then(m => m.default || m)
  },
  {
    name: indexoQ8J0TFk2LMeta?.name ?? "our-homes",
    path: indexoQ8J0TFk2LMeta?.path ?? "/our-homes",
    meta: indexoQ8J0TFk2LMeta || {},
    alias: indexoQ8J0TFk2LMeta?.alias || [],
    redirect: indexoQ8J0TFk2LMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyGMpgtjQ7RaMeta?.name ?? "privacy-policy",
    path: privacy_45policyGMpgtjQ7RaMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyGMpgtjQ7RaMeta || {},
    alias: privacy_45policyGMpgtjQ7RaMeta?.alias || [],
    redirect: privacy_45policyGMpgtjQ7RaMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93OitKyMtkIfMeta?.name ?? "promotions-slug",
    path: _91_46_46_46slug_93OitKyMtkIfMeta?.path ?? "/promotions/:slug(.*)*",
    meta: _91_46_46_46slug_93OitKyMtkIfMeta || {},
    alias: _91_46_46_46slug_93OitKyMtkIfMeta?.alias || [],
    redirect: _91_46_46_46slug_93OitKyMtkIfMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/promotions/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexeaeBqo94jcMeta?.name ?? "promotions",
    path: indexeaeBqo94jcMeta?.path ?? "/promotions",
    meta: indexeaeBqo94jcMeta || {},
    alias: indexeaeBqo94jcMeta?.alias || [],
    redirect: indexeaeBqo94jcMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: sandboxSpB5KEbBVCMeta?.name ?? "sandbox",
    path: sandboxSpB5KEbBVCMeta?.path ?? "/sandbox",
    meta: sandboxSpB5KEbBVCMeta || {},
    alias: sandboxSpB5KEbBVCMeta?.alias || [],
    redirect: sandboxSpB5KEbBVCMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: self_45guided_45home_45toursDVCME42KKfMeta?.name ?? "self-guided-home-tours",
    path: self_45guided_45home_45toursDVCME42KKfMeta?.path ?? "/self-guided-home-tours",
    meta: self_45guided_45home_45toursDVCME42KKfMeta || {},
    alias: self_45guided_45home_45toursDVCME42KKfMeta?.alias || [],
    redirect: self_45guided_45home_45toursDVCME42KKfMeta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/self-guided-home-tours.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93F3EAmNoh90Meta?.name ?? "testimonials-testimonial",
    path: _91testimonial_93F3EAmNoh90Meta?.path ?? "/testimonials/:testimonial()",
    meta: _91testimonial_93F3EAmNoh90Meta || {},
    alias: _91testimonial_93F3EAmNoh90Meta?.alias || [],
    redirect: _91testimonial_93F3EAmNoh90Meta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/testimonials/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: where_45we_45buildIJwjNUbKR3Meta?.name ?? "where-we-build",
    path: where_45we_45buildIJwjNUbKR3Meta?.path ?? "/where-we-build",
    meta: where_45we_45buildIJwjNUbKR3Meta || {},
    alias: where_45we_45buildIJwjNUbKR3Meta?.alias || [],
    redirect: where_45we_45buildIJwjNUbKR3Meta?.redirect,
    component: () => import("/codebuild/output/src2564421612/src/consort-headless/nuxtapp/pages/where-we-build.vue").then(m => m.default || m)
  }
]